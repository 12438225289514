//
// card.scss
//

.card {
    margin-bottom: $grid-gutter-width;
}

.card-link + .card-link {
    /*rtl:ignore*/
    margin-left: $card-spacer-x;
    /*rtl:ignore*/
    margin-right: 0;
}

.card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: '\F0415';
                }
            }
        }
    }
}

// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
    text-align: center;
    padding: 0.8rem 1.25rem;
}

.title {
    font-weight: bold;
}

//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: $card-overlay-bg;
    cursor: progress;

    .card-portlets-loader {
        background-color: $card-overlay-color;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 3px;
        margin-left: -($grid-gutter-width * 0.5);
        margin-top: -($grid-gutter-width * 0.5);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// Custom card box
.card-box {
    background-color: $card-bg;
    padding: $card-spacer-x;
    box-shadow: $box-shadow-sm;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
}

.header-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}

.client-card {
    background-color: grey;
    transition: transform 0.2s;
    border: 1px solid #e2e7f1;

    .card-title {
        color: white;
        padding-top: 10px;
    }

    &.notPublic {
        background-color: limegreen;
    }

    &.isPublic {
        background-color: #25afff;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
}

.clients-list {
    .card-body {
        padding: 1.25rem 1.25rem !important;
    }
}
