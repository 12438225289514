//
// _horizontal.scss
//

// Container width
body[data-layout-mode='horizontal'] {
    overflow: auto !important;

    @include media-breakpoint-up(xl) {
        .container-fluid {
            max-width: $horizontal-layout-width;
        }

        .navbar-custom {
            padding: 0 $grid-gutter-width;
        }
    }

    @include media-breakpoint-down(md) {
        .container-fluid {
            padding: 0 !important;
        }
    }

    .content-page {
        margin-left: 0 !important;
    }

    &.modal-open {
        overflow: hidden !important;
    }
}

.topnav {
    background: $bg-topbar-light;
    border-bottom: 1px solid $gray-300;
    margin-top: $topbar-height;
    padding: 0 calc(#{$grid-gutter-width} * 0.5);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;

    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        .nav-link {
            font-size: $menu-item-size;
            position: relative;
            line-height: 22px;
            padding: calc(#{$topnav-height - 22px} * 0.5) 1.1rem;
            color: $hori-menu-item-color;
            font-family: $font-family-secondary;

            i {
                font-size: 15px;
                display: inline-block;
            }

            svg {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin: -4px 5px 0 0;
                color: $hori-menu-item-color;
                fill: rgba($hori-menu-item-color, 0.12);
            }

            &:focus,
            &:hover {
                color: $menu-item-hover;
                background-color: transparent;

                svg {
                    color: $menu-item-hover;
                    fill: rgba($menu-item-hover, 0.12);
                }
            }

            &.active {
                svg {
                    color: $menu-item-hover;
                    fill: rgba($menu-item-hover, 0.12);
                }
            }
        }

        .nav-item {
            .dropdown.active {
                > a.dropdown-toggle {
                    color: $menu-item-active;
                }
            }
            &.active {
                > a {
                    color: $menu-item-active;
                    svg {
                        color: $menu-item-active;
                        fill: rgba($menu-item-active, 0.12);
                    }
                }
            }
            &:hover {
                color: $menu-item-hover;
                background-color: transparent;
            }
        }

        .menu-location {
            margin-right: 10px;
        }
    }
}

.animate-topnav {
    animation-name: topnav-animation;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
}

@keyframes topnav-animation {
    from {
        top: -55px;
    }
    to {
        top: 0px;
    }
}

@include media-breakpoint-up(lg) {
    .topnav {
        height: $topnav-height;

        .navbar-nav {
            .nav-item {
                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }

        .dropdown-item {
            padding: 0.5rem 1.25rem;
            min-width: 180px;
            margin: 0;
            width: auto;

            &.active {
                background-color: transparent;
                color: $menu-item-active;
            }
        }

        .dropdown {
            &.mega-dropdown {
                // position: static;
                .mega-dropdown-menu {
                    left: 0px;
                    right: auto;
                }
            }

            .dropdown-menu {
                padding: 0.3rem 0;
                margin-top: 0;
                border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

                .arrow-down {
                    &::after {
                        right: 20px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0 !important;
                        left: 100%;
                        display: none;
                    }
                }
            }

            &:hover {
                > .nav-link {
                    color: $menu-item-hover;

                    svg {
                        color: $menu-item-hover;
                        fill: rgba($menu-item-hover, 0.12);
                    }
                }

                > .dropdown-menu {
                    display: block;

                    > .dropdown:hover {
                        > .dropdown-item {
                            color: $menu-item-active;
                        }

                        > .dropdown-menu {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .navbar-toggle {
        display: none;
    }

    body[data-layout-mode='horizontal'] {
        .content-page {
            padding: $topnav-height 12px 65px 12px;
        }
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: '';
        height: 0.4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 10px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all 0.3s ease-out;
        width: 0.4em;
    }
}

@include media-breakpoint-down(xl) {
    .topnav-menu {
        .navbar-nav {
            li {
                &:last-of-type {
                    .dropdown {
                        .dropdown-menu {
                            right: 100%;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .topnav {
        overflow-y: auto;
        padding: 0;

        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.1rem;
            }
        }

        .dropdown {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 15px;
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;

                &.active,
                &:active {
                    color: $menu-item-active;
                }
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}

body[data-layout-mode='horizontal'][data-layout-width='boxed'] {
    .topnav {
        max-width: $boxed-layout-width;
        margin: $topbar-height auto 0;
    }
}

// dark-mode
body[data-layout-mode='horizontal'] {
    .logo-box {
        background-color: transparent !important;
    }
}

.topnavSpinner {
    text-align: center;
    margin-top: 75px;
}
