//
// location-header.scss
//

.location-header {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    h2 {
        display: inline-block;
        margin: 0;
    }

    .last-measured-values {
        float: right;

        .last-measured-date {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            font-size: 1.1em;
        }

        .pollution-badge {
            display: inline-block;
            width: 230px;
            height: 70px;
            color: white;
            padding: 10px;
            border-radius: 0.25rem;
            position: relative;

            .data-range-desc {
                font-size: 1.5em;
                text-transform: uppercase;
                font-weight: 600;
                display: table-caption;
                line-height: 1.2;
            }

            .quantity-name {
                position: absolute;
                bottom: 0;
                right: 0;
                padding-right: 75px;
                padding-bottom: 11px;
                font-weight: 600;
                font-size: 1.3em;

                &.big-number {
                    padding-right: 95px;
                }
            }

            .last-value {
                position: absolute;
                bottom: 0;
                right: 0;
                padding-right: 10px;
                font-weight: 600;
                font-size: 3.4em;

                &.big-number {
                    font-size: 2.4em;
                    padding-bottom: 4px;
                }
            }
        }

        @media only screen and (max-width: 900px) {
            float: none;
            padding-top: 20px;

            .last-measured-date {
                display: block;
                padding-bottom: 10px;
            }

            .pollution-badge {
                display: block;
            }
        }

        @media only screen and (max-width: 400px) {
            .pollution-badge {
                width: 100%;
            }
        }
    }
}
