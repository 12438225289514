//
// pollution-classes.scss
//

.pollution-classes {
    margin-top: 1vw;
}

p {
    font-size: 1.2em;
}

.table-title {
    font-weight: 600;
    font-size: 1.6em;
}

.table-1 {
    border: 1px solid black;
    background-color: white;

    .header {
        background-color: #e0e0e0;
    }

    td {
        border: 1px solid black;
        padding: 10px;
    }

    tr {
        border: 3px solid black;

        .td-border-right {
            border-right: 3px solid black;
        }
    }
}

.table-2 {
    border: 1px solid black;
    background-color: white;

    tr {
        border: 3px solid black;
        vertical-align: baseline;

        .td-border-right {
            border-right: 3px solid black;
        }
    }

    td {
        border: 1px solid black;
        padding: 10px;
        max-width: 150px;
    }

    .header {
        background-color: #e0e0e0;
    }
}

.green {
    background-color: #92d050;
}

.yellow {
    background-color: #ffff00;
}

.orange {
    background-color: #ff8c19;
}

.red {
    background-color: #ff0000;
}

.pollution-classes-title {
    text-decoration: underline;
}

.pollution-list {
    display: none;
}

.responsive {
    width: 100%;
    max-width: 500px;
    height: auto;
}

@media only screen and (max-width: 650px) {
    .pollution-list {
        display: block;
    }
    .pollution-table {
        display: none;
    }
}
