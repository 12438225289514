//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    overflow-x: hidden;
}

.loading img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
