//
// location-filters.scss
//
.no-filters {
    margin-top: 45px;
    margin-bottom: 10px;
}

.filter-refresh-overlay {
    opacity: 0.4;
}

.refresh-filter-text {
    top: 220px;
    position: relative;
    z-index: 99999;
    text-align: center;
    display: inherit;
    font-size: 28px;
    font-weight: 700;
    height: 0px;
}

.empty-filters {
    height: 2.6em;
}

.location-filter {
    .show-filters {
        height: 2.6em;
    }

    .show-filters input[type='checkbox'] {
        height: 1.5em;
        width: 2.5em;
    }

    .form-check-label {
        vertical-align: sub;
        padding-left: 4px;
    }

    .system-checkboxes div {
        display: inline-block;
        margin-right: 7px;
    }

    .system-checkboxes {
        display: inline-block;
    }

    .download {
        display: inline-block;
        float: right;

        .btn-light {
            background-color: #fff;
            border: 1px solid lightgray;
        }
    }

    .comparison-button {
        display: inline-block;
        float: right;

        .btn-info {
            background-color: $primary;
            border-color: $primary;

            &:hover {
                background-color: #1c81bb;
                border-color: #1c81bb;
            }
        }
    }

    .interval {
        display: inline-block;

        select {
            max-width: 200px;
        }
    }

    .second-filter-row {
        display: block;
    }

    .date-pickers {
        display: inline-block;
        margin-left: 20px;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-bottom: 20px;
        }

        input {
            caret-color: transparent;
            cursor: pointer;
        }
    }

    .interval-type {
        display: inline-block;
        margin-left: 20px;
        position: absolute;

        .btn.btn-info {
            background-color: $primary;
            border-color: $primary;

            &:hover {
                background-color: #1c81bb;
                border-color: #1c81bb;
            }

            &.active {
                background-color: #1c81bb;
                border-color: #1c81bb;
            }
        }
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month--disabled,
    .react-datepicker__year-text--disabled {
        color: #6c757d !important;
        background-color: #e4e4e4;
        cursor: not-allowed;

        &:hover {
            color: #6c757d !important;
            background-color: #e4e4e4;
            cursor: not-allowed;
        }
    }

    .react-datepicker__month-text {
        &:hover {
            background-color: $primary;
        }
    }

    .react-datepicker-wrapper {
        display: inline-block;
        &:nth-child(2) {
            margin-left: 20px;
        }
    }

    .interval-dates {
        width: 200px;
    }
}
